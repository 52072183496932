* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  background-color: #fafafa !important;
}

:root {
  --primary-color: #fce44d;
  --secondary-color: #374151;
}

/* custom scrollbar */

/* Customize website's scrollbar like Mac OS
Not supports in Firefox and IE */

/* total width */
::-webkit-scrollbar {
  background-color: #fff;
  width: 16px;
}

/* background of the scrollbar except button or resizer */
::-webkit-scrollbar-track {
  background-color: #fff;
}

/* scrollbar itself */
::-webkit-scrollbar-thumb {
  background-color: #babac0;
  border-radius: 16px;
  border: 4px solid #fff;
}

/* set button(top and bottom of the scrollbar) */
::-webkit-scrollbar-button {
  display: none;
}

.card-div {
  background: #ffffff;
  box-shadow: 0px 2px 10px rgb(0 0 0 / 5%);
  border-radius: 10px;
  padding: 30px;
}

.formContainer {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 10px;
  margin: 10px 0px;
  padding: 10px 0px;
  align-items: flex-start;
}

@media (max-width: 1024px) {
  .formContainer {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media (max-width: 768px) {
  .formContainer {
    grid-template-columns: repeat(1, 1fr);
  }
}

.formContainer-2 {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 10px;
  margin: 10px 0px;
  padding: 10px 0px;
  align-items: flex-start;
}

@media (max-width: 1024px) {
  .formContainer-2 {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 768px) {
  .formContainer-2 {
    grid-template-columns: repeat(1, 1fr);
  }
}

.formContainer-3 {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 10px;
  margin: 10px 0px;
  padding: 10px 0px;
  align-items: flex-start;
}

.logoBackground {
  background-color: #fce44d;
}

.dualContainer {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 10px;
}

@media (max-width: 800px) {
  .dualContainer {
    gap: 0px;
    grid-template-columns: 1fr;
  }
}
