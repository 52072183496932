.completed-date {
  background-color: #2e7d32;
  color: white;
}

.skipped-date {
  background-color: #ed6c02;
  color: white;
}

.missed-date {
  background-color: #d32f2f;
  color: white;
}

.deliverable-date {
  background-color: lightgreen;
  color: white;
}

.non-deliverable-date {
  background-color: gray;
  color: white;
}
