.header {
  display: flex;
  justify-content: space-between;
  padding: 0 !important;
}
.left {
  width: 30%;
  display: flex;
  justify-content: flex-start;
}
.center {
  width: 30%;
  display: flex;
  justify-content: center;
}
.right {
  width: 30%;
  display: flex;
  justify-content: flex-end;
}
